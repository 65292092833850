<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.customerList')"
          icon="mdi-account"
        >
          <v-row no-gutters>
            <v-col cols="12" lg="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />
            </v-col>

            <v-spacer />

            <v-col
              v-if="$vuetify.breakpoint.lgAndUp"
              cols="12"
              lg="3"
              class="d-flex justify-end align-center"
            >
              <v-btn
                color="green"
                elevation="2"
                @click="handleBatchDialog"
                v-if="permissionCheck('add')"
              >
                <v-icon left>mdi-file-import</v-icon>
                {{ $vuetify.lang.t("$vuetify.batchUpload") }}
              </v-btn>

              <v-btn
                color="green"
                elevation="2"
                @click="handleDialog"
                v-if="permissionCheck('add')"
              >
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>

            <v-col
              v-else
              cols="12"
              lg="3"
              class="d-flex justify-end align-center"
            >
              <v-btn
                color="green"
                elevation="2"
                @click="handleBatchDialog"
                v-if="permissionCheck('add')"
              >
                <v-icon>mdi-file-import</v-icon>
              </v-btn>

              <v-btn
                color="green"
                elevation="2"
                @click="handleDialog"
                v-if="permissionCheck('add')"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <TableImg
                :head="headers"
                :data="datas"
                :search="search"
                :dialog.sync="dialog"
                :tableLoading="tableLoading"
                v-on:edititem="handleEditDialog"
                v-on:deleteitem="handleDeleteDialog"
              />
            </v-col>
          </v-row>

          <ListCRUD
            :dialog.sync="dialog"
            :updataData="updataData"
            :paymentPartyDetail="paymentPartyDetail"
            :contacts_str="contacts_str"
            @handleData="handleEditConfirm"
          />

          <DialogDelete
            :dialogDelete.sync="dialogDelete"
            :deleteObj="deleteObj"
            @handleDelete="handleDeleteConfirm"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//TODO missing Batch-Upload

import { customerList, customerUpsert, customerDel } from "@/api/basicdata";
import { profilePicUpload } from "@/api/company";

const newSearch = () => {
  return {
    skip: 0,
    limit: 9999,
    saler_id: "",
    key: "",
  };
};

const newUpData = () => {
  return {
    id: "",
    code: "",
    customer_type: 0,
    avatar: "", // 头像
    company: "",
    nation_code: "855", // 国家变化
    tel: "",
    sex: 1,
    birthday: "",
    fax: "",
    email: "",
    address: "",
    note: "",
    contacts: [], // 收货地址
    payment_party_detail: [], // 开票信息
  };
};

const newPaymentDetail = () => {
  return {
    company_name: "",
    business_code: "",
    openning_bank: "",
    bank_acount: "",
    swift_code: "",
  };
};

const newContacts = () => {
  return [
    {
      contact: "",
      tel: "",
      province: "",
      city: "",
      district: "",
      deliery_addr: "",
    },
  ];
};

export default {
  components: {
    TableImg: () => import("@/components/tableImg"),
    ListCRUD: () => import("@/components/customers/listCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      wait: false,
      tableLoading: true,
      deleteObj: {},
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.customerName"),
          value: "company",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ID"),
          value: "code", //Should be CompanyId
        },
        {
          text: this.$vuetify.lang.t("$vuetify.qty1"),
          value: "total_order",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.TotalAmount"),
          value: "total_money",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactPerson"),
          value: "contacts[0].contact",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.mobile"),
          value: "contacts[0].tel", //should be phone
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "CustomerListAction",
          sortable: false,
          align: "center",
        },
      ],
      datas: [],
      index: [],
      search: "",
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.company") },
        { text: this.$vuetify.lang.t("$vuetify.customerList") },
      ],
      searchForm: newSearch(),
      updataData: newUpData(),
      paymentPartyDetail: newPaymentDetail(),
      contacts_str: newContacts(),
    };
  },
  methods: {
    handleDialog() {
      this.dialog = true;
      this.updataData = newUpData();
      this.paymentPartyDetail = newPaymentDetail();
      this.contacts_str = newContacts();
    },
    handleEditDialog(obj) {
      if (this.permissionCheck("modify")) {
        this.updataData = obj;
        this.paymentPartyDetail = obj.payment_party_detail;
        if (obj.contacts.length == 0) {
          this.contacts_str = newContacts();
        } else {
          this.contacts_str = obj.contacts;
        }

        this.updataData.birthday =
          this.updataData.birthday === "--" ? "" : this.updataData.birthday;
        this.dialog = true;
      }
    },
    handleEditConfirm(obj, files) {
      this.dialog = false;

      if (files != undefined) {
        const imageForm = new FormData();
        imageForm.append("file", files);

        profilePicUpload(imageForm)
          .then((res) => {
            obj.avatar = res.data.md5;
            this.updataData = obj;

            this.wait = true;
          })
          .catch((err) => console.log("Customer Profile Error", err));
      } else {
        this.updataData = obj;
        this.wait = true;
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      customerDel(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
        })
        .catch((err) => console.log("Customer Delete Error", err));
    },
    handleBatchDialog() {},
    getData() {
      this.tableLoading = true;

      customerList(this.searchForm)
        .then((data) => {
          this.datas = [...data.items];

          for (var i = 0; i < this.datas.length; i++) {
            this.datas[i].itemNo = i + 1;
          }
          this.tableLoading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    wait: function () {
      if (this.wait) {
        customerUpsert(this.updataData)
          .then(() => {
            this.getData();
            this.wait = false;
          })
          .catch((err) => console.log("Customer List Error", err));
      }
    },
  },
};
</script>
